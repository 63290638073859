crm login starts here

a{
    text-decoration: none;
}


.crm-header-block {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-left: 5px;
}

.crm-header-profile-block {
    display: flex;
    align-items: center;
    gap: 15px;
    grid-gap: 15px;
}

.crm-header-profile-block-item.profile img {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.crm-header-profile-block-item a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border-radius: 3px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.crm-header-profile-block-item.sign-out a {
    color: #77828F;
    background: rgba(235, 235, 235, 0.4);
}
.crm-header-profile-block-item.take-break a {
    background: rgba(255, 244, 215, 0.4);
    color: #532A1E;
}
.crm-header-profile-block-item.working-time a {
    background: rgba(195, 237, 216, 0.3);
    color: #0F1E54;
}
.crm-header-profile-block-item.alert-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: rgba(119, 130, 143, 0.78);
    padding-right: 0;
}



.crm-login-alert-block {
    display: flex;
    align-items: center;
    gap: 15px;
    grid-gap: 15px;
}

.login-alert-icon {
    position: relative;
    display: flex;
    background: #E3F0FF;
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.login-alert-icon::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 35px;
    background: conic-gradient(from 180deg at 50% 50%, #1C83FB 0deg, #C8E0FF 360deg);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 50%;
}
.login-alert-icon.on-time::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 35px;
    background: conic-gradient(from 180deg at 50% 50%, #16CC70 0deg, #E4F9EA 360deg);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 50%;
}
.login-alert-icon.late-time::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 35px;
    background: conic-gradient(from 180deg at 50% 50%, #F8434E 0deg, rgba(255, 217, 217, 0) 360deg);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 50%;
}



.login-alert-text {
    border-radius: 31px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 25px;
}

.cr-time{
    background: rgba(105, 174, 255, 0.19);
    color: #0F1E54;
}
.on-time {
    background: #E4F9EA;
}
.late-time {
    background: rgb(255 240 240);
}
.login-alert-text.on-time b {
    color: #16CC70;
}
.login-alert-text.late-time b {
    color: #F8434E !important;
}

a.close {
    margin-left: 20px;
}
/*crm login end here*/




.css-1vgj6wr-MuiButtonBase-root-MuiButton-root, .css-1yo9kdb-MuiButtonBase-root-MuiButton-root, button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.css-15puwmf-MuiButtonBase-root-MuiButton-root, button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.css-k9x1d7-MuiButtonBase-root-MuiButton-root, button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.css-aaamal-MuiButtonBase-root-MuiButton-root, button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.css-ui6e1j-MuiButtonBase-root-MuiButton-root, button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.css-svf30e-MuiButtonBase-root-MuiButton-root, .css-1rt8pkh-MuiButtonBase-root-MuiButton-root, button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.css-146lx5y-MuiButtonBase-root-MuiButton-root{
    min-width: 90% !important;
}


td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall.css-q6eo78-MuiTableCell-root button{
    min-width: 90% !important;
}

tr.MuiTableRow-root.css-18qx59p-MuiTableRow-root:hover {
    background-color: rgb(243, 242, 255);
    transition: .25s;
}

.task-assigned-to-me div {
    color: rgb(255, 100, 46);
}

.ass-me-block{
    border-left: 4px solid rgb(255, 100, 46);
}

.task-assigned-by-me div{
    color: rgb(87, 155, 252);
}

.task-assigned-by-me .ass-me-block {
    border-left: 4px solid rgb(87, 155, 252);
}

.to-review div{
    color: rgb(162, 93, 220);
}

.to-review .ass-me-block {
    border-left: 4px solid rgb(162, 93, 220);
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
 
    
}

.custom-list-icon {
    min-width: 30px !important;
 
  }
  .dash-icons{
    font-size: 19px !important ;
  }

.viewmodal{
padding: 0 !important;
  }

  .create-btn{
    background-color:rgb(63,197,149) !important;
    padding: 8px !important;
    margin-right:17px  !important;
   
  }

  .edit-btn {

    outline: 2px solid green  ; /* Sets the outline to 2 pixels wide and green in color */
}
.tab-color {
    color: rgb(34,167,119) !important;
  }
  .MuiTabs-indicator {
    background-color: rgb(87,182,139) !important; /* Set the background color to red */
  }

  .ld-circle{
    color:rgb(87,182,139)  !important ;
  }

  .custom-cell {
    padding: 4px; /* Set your desired padding value */
}
.MuiDataGrid-cell.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell--textLeft {
    padding: 4px; /* Set your desired padding value here */
}
.css-hyum1k-MuiToolbar-root{
    padding: 0 !important;
}
.page_header{
    background-color: transparent !important;
}

.MuiDataGrid-columnHeader-MuiDataGrid-columnHeader{
    padding: 0 !important;
}
.MuiDataGrid-columnHeaderTitleContainer{
    padding: 0 !important;
}
.customHeaderCell {
    padding: 0 !important;
  }
  .MuiSvgIcon-root-MuiSvgIcon-fontSizeMedium-css-17ceore-MuiSvgIcon-root{
    color: inherit !important;
  }

.MuiInputBase-input-MuiFilledInput-input-Mui-disabled-MuiInputBase-inputMultiline-css-crcv0s-MuiInputBase-input-MuiFilledInput-input{
    -webkit-text-fill-color: black !important;
  }
.MuiInputBase-input-MuiFilledInput-input-Mui-disabled-css-auudwm-MuiInputBase-input-MuiFilledInput-input{
    -webkit-text-fill-color: black !important;
}
.Submenu{
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
}
.MuiDataGrid-columnHeaders-css-18vwvjw-MuiDataGrid-columnHeaders{
    background-color: rgb(248,250,251) !important;
}

/* Rich Text Box */

.rdw-editor-main{
    height: 600px !important;
  border: 1px solid gray !important;
  overflow: hidden;

}
.notranslate.public-DraftEditor-content{
    overflow-x: hidden !important;
  
padding: 10px;
}
.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr{
    margin: 0 !important;
}

#ui-ball-loader{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#data-not-found-msg{
 width:  100%;
 height: 300px;
 display:flex;
 justify-content: center;
}

#detail-text{
    font-weight: 500;
 font-size: 1rem;   
 color: rgba(133, 133, 133, 1);
}

#detail-text-inline{
  display: inline;
    padding-left:10px;
    font-size: 16px;
    color: black;
    font-weight: 500;
}

.cursor{
    cursor: pointer;
}


.filter-selectx .css-b62m3t-container .css-13cymwt-control{
    border-radius: 12px !important;
}
.css-1x210aq-MuiFormControl-root-MuiTextField-root .MuiFilledInput-root.Mui-disabled{
    background-color: transparent !important;
}
.css-auudwm-MuiInputBase-input-MuiFilledInput-input.Mui-disabled{
    -webkit-text-fill-color: black !important;
}
.css-11hbx66-MuiInputBase-root-MuiOutlinedInput-root{
    height: 40px;
}

#permission-btn-list{
    font-size: 15px;
    background-color: #ceffee;
    height: 28px;
    color: black;
    margin: 4px;
    padding-right: 3px;
}
#permission-delete-icon {
    margin: 0;
    padding: 0;
   

}

#permission-delete-icon:hover {
    background-color: #FFBDAD  !important; /* Change the background color on hover */
}
#date-range-input{
    color: black !important;
    -webkit-text-fill-color:black
}